// extracted by mini-css-extract-plugin
export var active = "client-member-preview-module--active--614ce";
export var backgroundAnimation = "client-member-preview-module--backgroundAnimation--2fd9a";
export var buttons = "client-member-preview-module--buttons--3ba37";
export var column = "client-member-preview-module--column--422c4";
export var container = "client-member-preview-module--container--f2024";
export var divider = "client-member-preview-module--divider--c23b0";
export var grid = "client-member-preview-module--grid--c1c09";
export var inactive = "client-member-preview-module--inactive--e609b";
export var isLoading = "client-member-preview-module--is-loading--28247";
export var loader = "client-member-preview-module--loader--77d9e";
export var opacity = "client-member-preview-module--opacity--b529b";
export var radiusBox = "client-member-preview-module--radius-box--749b9";
export var sectionBox = "client-member-preview-module--section-box--937cc";
export var sectionTitle = "client-member-preview-module--section-title--e8c3a";
export var team = "client-member-preview-module--team--23599";
export var topWrapper = "client-member-preview-module--top-wrapper--e4143";
export var wrapper = "client-member-preview-module--wrapper--bf3ec";