import SectionHeaderClient from '../components/sections/header-client';
import SectionClientMemberPreview from '../components/sections/client-member-preview';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';

import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import ThemeStyle from '../components/atoms/theme-style';

interface IIndexProps {
    readonly data: {
        page: IPage;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const Index: React.FC<IIndexProps> = ({ data }) => {
    const { page, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    const sections = getSectionsWithRepeatIndex(page.sections).map((section) => {
        return section.type === 'article-preview'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            {sections.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                    />
                );
            })}
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Index;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header-client': SectionHeaderClient,
    'client-member-preview': SectionClientMemberPreview,
    'footer': SectionFooter,
};