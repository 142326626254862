import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    grid,
    topWrapper,
    container,
    radiusBox,
    divider,
    wrapper,
    column,
    loader,
    buttons,
    sectionTitle,
    inactive,
    active,
    team,
    isLoading,
    opacity,
} from './client-member-preview.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { TLocale } from '../../locale';
import { IClientTeam } from '../../models/client-team.model';
import useTranslations from '../../hooks/use-translations';
import { useClientMember } from '../../hooks/use-client-member';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { useClientPermission } from '../../hooks/use-client-permission';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useModal } from '../../hooks/use-modal';
import { useBackLink } from '../../hooks/use-back-link';
import { useClient } from '../../hooks/use-client';

import RadiusBox from '../hoc/radius-box';
import DashboardHeader from '../molecules/dashboard-header';
import Button from '../atoms/button';
import DetailsColumn from '../atoms/details-column';
import Loader from '../atoms/loader';
import Section from '../hoc/section';
import NoPermissionInfo from '../organisms/no-permission-info';
import IconFactory from '../hoc/icon-factory';
import { getUrlParamValue } from '../../utils/get-url-param-value';

interface IClientMemberPreview {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientMemberPreviewQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientMemberPreview: React.FC<IClientMemberPreview> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { addModal } = useModal();
    const client = useClient();
    const memberId = getUrlParamValue('id');
    const member = useClientMember({ queries: ['single'], memberId });
    const memberData = member.single.data;
    const t = useTranslations('ClientMemberPreview');
    const canUsers = useClientPermission(['can-users']);
    const { allPage } = useStaticQuery<IClientMemberPreviewQueryResult>(query);
    const clientMembersPage = usePagePathname(allPage, 'client-members');
    const clientTeamsPage = usePagePathname(allPage, 'client-teams');
    const prevPathname = useBackLink(clientMembersPage);
    const clientMemberCreatePage = usePagePathname(allPage, 'client-member-form');

    const { style, sectionId, css } = section;

    const handleMemberDelete = () => {
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleMemberDeleteConfirmation },
        });
    };

    const handleMemberDeleteConfirmation = async () => {
        const memberId = memberData?.memberId;
        try {
            await member.delete.fetch(memberId).unwrap();
            navigate(prevPathname, { replace: true });
        } catch {
            addModal({ modalKey: 'delete-error-modal' });
        }
    };

    if (!canUsers) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${member.single.isLoading ? isLoading : ''} ${
                member.delete.isLoading ? opacity : ''
            }`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {memberData ? (
                <div className={container}>
                    <div className={topWrapper}>
                        <DashboardHeader hint={t.hint(clientTeamsPage)} TitleTag={TitleTag}>
                            {t.title}
                        </DashboardHeader>
                        <div className={buttons}>
                            {client.data?.memberId !== memberData.memberId && (
                                <Button stylePreset="danger" onClick={handleMemberDelete}>
                                    <IconFactory icon="trash" />
                                </Button>
                            )}
                            <Button
                                stylePreset="secondary"
                                as="link"
                                to={`${clientMemberCreatePage}?id=${memberData.memberId}`}
                            >
                                <IconFactory icon="edit" />
                            </Button>
                        </div>
                    </div>
                    <RadiusBox className={radiusBox}>
                        <div className={wrapper}>
                            <DetailsColumn
                                className={column}
                                label={t.createdAt}
                                values={[`${getDateFromUnixTimestamp(memberData.createdAt)}`]}
                            />
                            <DetailsColumn
                                className={column}
                                label={t.role.label}
                                values={[`${getRoleTranslation(memberData.role, t)}`]}
                            />
                            <DetailsColumn
                                className={`${column} ${
                                    memberData.status === 0 ? inactive : active
                                }`}
                                label={t.status.label}
                                values={[`${getStatusTranslation(memberData.status, t)}`]}
                            />
                            <DetailsColumn
                                className={`${column} ${team}`}
                                label={t.team}
                                values={[getTeamString(memberData.teams)]}
                            />
                        </div>
                        <div className={divider}></div>
                        <p className={sectionTitle}>{t.data}</p>
                        <div className={wrapper}>
                            <DetailsColumn
                                className={column}
                                label={t.id}
                                values={[`#${memberData.memberId}`]}
                            />
                            <DetailsColumn
                                className={column}
                                label={t.name}
                                values={[`${memberData.firstName}`]}
                            />
                            <DetailsColumn
                                className={column}
                                label={t.lastname}
                                values={[`${memberData.lastName}`]}
                            />
                            {memberData.phone && (
                                <DetailsColumn
                                    className={column}
                                    label={t.phone}
                                    values={[`${memberData.phone}`]}
                                />
                            )}
                            <DetailsColumn
                                className={column}
                                label={t.mail}
                                values={[`${memberData.email}`]}
                            />
                        </div>
                    </RadiusBox>
                </div>
            ) : (
                <Loader className={loader} />
            )}
        </Section>
    );
};

const getTeamString = (teams: IClientTeam[]) => {
    let string = '';

    if (teams.length === 0) {
        return '-';
    }

    teams.forEach((team, i) => {
        if (i > 0) {
            string += ', ' + team.name;
        } else {
            string += team.name;
        }
    });
    return string;
};

const getStatusTranslation = (status: number, t: TLocale['ClientMemberPreview']) => {
    switch (status) {
        case 0:
            return t.status.inactive;
        case 1:
            return t.status.active;
    }
};

const getRoleTranslation = (role: string, t: TLocale['ClientMemberPreview']) => {
    switch (role) {
        case 'super-user':
            return t.role.superUser;
        case 'talent-acquisition':
            return t.role.talentAcquisition;
        case 'collaborator':
            return t.role.collaborator;
    }
};

export default ClientMemberPreview;

const query = graphql`
    query {
        allPage(
            filter: { type: { in: ["client-member-form", "client-members", "client-teams"] } }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;
